import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as url from "../Utils/url";
import { apiReq } from "../Utils/utils";
import Logo from "../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import DesktopMenu from "./DesktopMenu";
import CourseCategory from "./CourseCategory";
import UserIcon from "../assets/icons/user.svg";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import moment from "moment";
import RightNavBar from "./RightNavBar";

function HeaderNew() {
  const [courses, setCourses] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isCoursesOpen, setIsCourseOpen] = useState(false);
  const [isOpenUser, setIsOpenUser] = useState(false);
  const [isNoticed, setIsNoticed] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [modal, setModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [bundles, setBundles] = useState([]);
  const [popUpData, setPopUpData] = useState(null);
  const [closeedStrip, setCloseedStrip] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledMobile, setScrolledMobile] = useState(false);

  const history = useHistory();
  const website = useSelector((state) => state.website);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isUser = useSelector((state) => state.user.user);
  const dispatch = useDispatch();

  const toggle = () => setModal(!modal);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    apiReq(
      `${url.CREATE_CONTACT_ENQUIRY}`,
      {
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        message: data.message,
      },
      "post"
    )
      .then((res) => {
        if (res.success) {
          setIsSuccess(true);
          reset();
          setTimeout(() => {
            setModal(!modal);
            setIsSuccess(false);
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log("history>>", history.location.pathname);

  useEffect(() => {
    const bodyTag = document.getElementsByTagName("body")[0];

    if (isOpen) {
      bodyTag.style.overflow = "hidden";
    } else {
      bodyTag.style.overflow = "visible";
    }
  }, [isOpen]);

  useEffect(() => {
    const isNot = sessionStorage.getItem("isNoticed");
    if (isNot === "false") {
      setIsNoticed(false);
    } else {
      setIsNoticed(true);
    }
  }, []);

  // get notifications
  const getNotifications = () => {
    // GET_NOTIFICATION

    apiReq(`${url.GET_NOTIFICATION}${isUser?.id}`, {}, "get")
      .then((result) => {
        if (result.success) {
          // console.log("success >> ", result.data);
          setNotifications(result.data.reverse());
          if (result.unReadNotifications.length > 0) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        }
      })
      .catch((err) => {
        console.log("error >> ", err);
      });
  };

  // update notification status
  const updateNotificationStatus = (id) => {
    apiReq(
      `${url.UPDATE_NOTIFICATION}${id}`,
      {
        userId: isUser?.id,
      },
      "put"
    )
      .then((res) => {
        if (res.success) {
          getNotifications();
        }
      })
      .catch((err) => {
        console.log("error >> ", err);
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      getNotifications();
    }
  }, [isLoggedIn]);

  const getAllCourses = () => {
    apiReq(url.GET_COURSES_VIA_CATEGORY, {}, "get")
      .then((result) => {
        if (result.success) {
          const categoryWise = [...result.data].sort(
            (a, b) => a.sortOrder - b.sortOrder
          );
          setCourses(categoryWise);
        }
        //   console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // GET_POPUP
  const getPopup = () => {
    apiReq(url.GET_POPUP, {}, "get")
      .then((result) => {
        if (result.success) {
          if (result.data.length > 0) {
            setPopUpData(result.data[0]);
          } else {
            setPopUpData(null);
          }
        }
        //   console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // get bundle list
  const getBundleList = () => {
    apiReq(url.GET_ALL_BUNDLES, {}, "get")
      .then((result) => {
        if (result.success) {
          const newData =
            result.data.length > 0
              ? result.data.filter(
                  (item) => item._id !== "6261a4da7d3e716cffde8720"
                )
              : [];

          const arrNew = newData.filter((item) => item.enrollment === true);
          setBundles(arrNew);
        }
        //   console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllCourses();
    getBundleList();
    getPopup();
    getNotifications();
    localStorage.removeItem("isNoticed");
  }, []);

  // mobileLinkHandle
  const mobileLinkHandle = (link, type) => {
    if (type !== "bundle") {
      history.push(`/${link}`);
    } else {
      history.push(`/bundle/${link}`);
    }

    setIsOpen(false);
  };

  // handle logout
  const handleLogout = () => {
    localStorage.removeItem("prepFront");
    dispatch({ type: "USER_LOGOUT", payload: "LOGOUT" });
    history.push("/");
  };
  // handleIsNoticed
  const handleIsNoticed = () => {
    sessionStorage.setItem("isNoticed", false);
    setIsNoticed(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 150) {
        setScrolled(true);
        setScrolledMobile(true);
      } else {
        setScrolled(false);
        setScrolledMobile(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navbarClasses = ["desktop_header"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }
  let navbarClassesMobile = ["nav_bar_main mobile_navbar"];
  if (scrolled) {
    navbarClassesMobile.push("scrolled");
  }

  // console.log("popup data", popUpData);
  return (
    <>
      <div className="right_fixed_button" onClick={toggle}>
        <span className="call_back_btn">Free Demo</span>
      </div>
      {/* Mdoal for request a call back */}
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Contact Us</ModalHeader>
        <ModalBody>
          <div className="">
            {isSuccess ? (
              <div className="contact_success_wrapper">
                <div className="text-center">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 130.2 130.2"
                  >
                    <circle
                      className="path circle"
                      fill="none"
                      stroke="#00e676"
                      strokeWidth={6}
                      strokeMiterlimit={10}
                      cx="65.1"
                      cy="65.1"
                      r="62.1"
                    />
                    <polyline
                      className="path check"
                      fill="none"
                      stroke="#00e676"
                      strokeWidth={6}
                      strokeLinecap="round"
                      strokeMiterlimit={10}
                      points="100.2,40.2 51.5,88.8 29.8,67.5 "
                    />
                  </svg>
                </div>
                <h5 className="mt-5 mb-4 text-center">
                  Thank You! for contact us. We&apos;ll call you shortly.
                </h5>
              </div>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="block">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    ref={register({ required: true })}
                  />
                  {errors.name && (
                    <label className="text-danger">
                      Please Enter Your Name
                    </label>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="block">Mobile Number</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile No."
                        name="mobile"
                        ref={register({
                          required: true,
                          minLength: 10,
                        })}
                      />
                      {errors.mobile && (
                        <label className="text-danger">
                          Please Enter a Valid Mobile Number.
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label className="block">Email Address</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        ref={register({
                          required: "Please enter your email address.",
                          pattern: {
                            value:
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Please enter a valid email address.",
                          },
                        })}
                      />
                      {errors.email && (
                        <label className="text-danger">
                          {errors.email.message}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="block">Message</label>
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    name="message"
                    ref={register({ required: true })}
                    style={{ minHeight: 120 }}
                  ></textarea>
                </div>
                <div className="d-flex justify-content-center">
                  <button type="submit" className="btn btn-large btn-primary">
                    &nbsp;&nbsp;&nbsp;Submit&nbsp;&nbsp;&nbsp;
                  </button>
                </div>
              </form>
            )}
          </div>
        </ModalBody>
      </Modal>

      {/* top strip */}
      {popUpData && (
        <>
          {popUpData.title && (
            <>
              {/* {isNoticed && (
                <div className="top_strip_wrapper">
                  <div className="container">
                    <div className="ts_content">
                      <span class="mr-4">{popUpData.title}</span>
                      <a
                        href={popUpData.cta_link}
                        className="strip_btn"
                        target={"_blank"}
                      >
                        {popUpData.cta_title}
                      </a>
                      <span className="close_st" onClick={handleIsNoticed}>
                        <i className="fa fa-times"></i>
                      </span>
                    </div>
                  </div>
                </div>
              )} */}
              {!closeedStrip && (
                <div className="top_strip_wrapper">
                  <div className="container">
                    <div className="ts_content">
                      <span class="mr-4">{popUpData.title}</span>
                      <a
                        href={popUpData.cta_link}
                        className="strip_btn"
                        target={"_blank"}
                      >
                        {popUpData.cta_title}
                      </a>
                    </div>
                  </div>
                  <span
                    className="close_st"
                    onClick={() => setCloseedStrip(true)}
                  >
                    <svg
                      viewBox="0 0 512 512"
                      fill="currentColor"
                      height="1.5em"
                      width="1.5em"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={32}
                        d="M368 368L144 144M368 144L144 368"
                      />
                    </svg>
                  </span>
                </div>
              )}
            </>
          )}
        </>
      )}
      {/* End top strip */}

      {/* main Desktop Header */}
      <div className={navbarClasses.join(" ")}>
        <div className="cta_buttons_wrapper">
          <div className="container">
            <div className="cta_buttons">
              <Link
                to={`/gdpi-advanced-program`}
                className="cta_btn_custom cta_btn_bg_primary"
              >
                India’s #1 GDPI Batch
              </Link>
              <a
                href={`https://chat.whatsapp.com/InIjdUsQCR70d4nnpxShPh`}
                target={"_blank"}
                className="cta_btn_custom cta_btn_bg_whatsapp"
              >
                <svg
                  viewBox="0 0 448 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                </svg>{" "}
                CAT Batch Demo
              </a>
              <a
                href={`https://www.myprepzone.com/bundle/cat-2025-live-full-course-basic-to-advanced-with-personalised-mentorship`}
                target={"_blank"}
                className="cta_btn_custom cta_btn_bg_darkgreen"
                title="CAT 2025 LIVE Batch 1"
              >
                CAT 2025 LIVE Batch 1
              </a>
              <a
                href={`https://www.myprepzone.com/bundle/cat-platinum-full-course-2-year-validity-personalised-mentorship-topic-wise-assignment`}
                target={"_blank"}
                className="cta_btn_custom cta_btn_bg_yellow"
              >
                CAT 2025 - Only Mentorship
              </a>
              <div className="social_icon_buttonss">
                <a
                  href={`https://www.instagram.com/mbaprepzone/`}
                  target={"_blank"}
                  className="cta_btn_custom_social cta_btn_bg_insta"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    fill="currentColor"
                    height="1em"
                    width="1em"
                  >
                    <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 00-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z" />
                  </svg>
                </a>
                <a
                  href={`https://www.youtube.com/channel/UCdMIUMS6rPVVL_yHIUMHdrQ?sub_confirmation=1`}
                  target={"_blank"}
                  className="cta_btn_custom_social cta_btn_bg_yt"
                >
                  <svg
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                  >
                    <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 011.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 01-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 01-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 010 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 011.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 017.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header_main">
          {/* End mdoal for request a call back */}
          <div className="container">
            {/* Desktop Navbar */}
            <div className="nav_bar_main desktop_navbar">
              <div className="company_logo">
                <NavLink className="navbar-brand" to="/">
                  <img
                    src={website.data !== null ? website.data.logo : Logo}
                    alt="My Prepzone"
                    className="web_logo"
                  />
                </NavLink>
              </div>

              <div className="right_part_navbar">
                <div className="main_nav_bar">
                  <DesktopMenu courses={courses} bundles={bundles} />
                </div>
                <div className="user_auth_area d-flex">
                  {isLoggedIn && (
                    <li className="nav-item dfc dropdown mr-2">
                      {isVisible && <span className="notification_dot"></span>}
                      <svg
                        fill="currentColor"
                        viewBox="0 0 16 16"
                        height="1em"
                        width="1em"
                      >
                        <path d="M8 16a2 2 0 002-2H6a2 2 0 002 2zM8 1.918l-.797.161A4.002 4.002 0 004 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 00-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5.002 5.002 0 0113 6c0 .88.32 4.2 1.22 6z" />
                      </svg>
                      <ul
                        className="dropdown-menu notification_dropdown"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div className="notification_list">
                          {notifications.length > 0 ? (
                            <>
                              {notifications.map((item) => {
                                const isReaded = item.readby.some(
                                  (us) => us.readerId === isUser?.id
                                );
                                return (
                                  <Link
                                    to={`${
                                      item.type === "course" && "/course"
                                    }/${item.productId}`}
                                    className={`notification ${
                                      isReaded ? "read" : " "
                                    }`}
                                    key={item._id}
                                    onClick={() =>
                                      !isReaded
                                        ? updateNotificationStatus(item._id)
                                        : console.log("clicked")
                                    }
                                  >
                                    <div className="notification_image">
                                      <i className="fa fa-refresh" />
                                    </div>
                                    <div className="notification_content">
                                      <div className="title_and_date">
                                        <strong>Admin</strong> {item.message}
                                      </div>
                                      <span className="notification_date">
                                        {moment(item.createdAt).fromNow()}
                                      </span>
                                    </div>
                                  </Link>
                                );
                              })}
                            </>
                          ) : (
                            <small>No notifications</small>
                          )}
                        </div>
                      </ul>
                    </li>
                  )}
                  {isLoggedIn ? (
                    <li className="nav-item dfc dropdown">
                      <a
                        className="dfc"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style={{ cursor: "pointer" }}
                      >
                        <div className="avatar_header">
                          {isUser?.profile_picture !== null ? (
                            <img src={isUser?.profile_picture} />
                          ) : (
                            <span>{isUser?.name.substring(0, 1)}</span>
                          )}
                        </div>
                        <span className="user_name">{isUser?.name}</span>
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <li>
                          <Link to="/user/dashboard" className="dropdown-item">
                            <i className="fa fa-user-circle"></i> Profile
                          </Link>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleLogout()}
                          >
                            <i className="fa fa-sign-out"></i> Logout
                          </a>
                        </li>
                      </ul>
                    </li>
                  ) : (
                    <div>
                      <Link className="btn btn-link mr-2" to="/login">
                        Sign in
                      </Link>
                      <Link className="btn btn-primary" to="/register">
                        Sign up
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* End Desktop Navbar */}
          </div>
        </div>
      </div>
      {/* End main Desktop Header */}
      {/* Mobile Navbar */}
      <div className={navbarClassesMobile.join(" ")}>
        <div className="cta_buttons_wrapper">
          <div className="container">
            <div className="cta_buttons" style={{ justifyContent: "center" }}>
              <a
                href={`https://www.myprepzone.com/bundle/cat-2025-live-full-course-basic-to-advanced-with-personalised-mentorship`}
                target={"_blank"}
                className="cta_btn_custom cta_btn_bg_darkgreen"
                title="CAT 2025 LIVE Batch 1"
              >
                CAT 2025 LIVE Batch 1
              </a>
              <Link
                to={`/gdpi-advanced-program`}
                className="cta_btn_custom cta_btn_bg_primary"
              >
                India’s #1 GDPI Batch
              </Link>
              <a
                href={`https://www.youtube.com/channel/UCdMIUMS6rPVVL_yHIUMHdrQ?sub_confirmation=1`}
                target={"_blank"}
                className="cta_btn_custom_social cta_btn_bg_yt"
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 011.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 01-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 01-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 010 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 011.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 017.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="top_section">
          <div className="company_logo">
            <NavLink className="navbar-brand" to="/">
              <img
                src={website.data !== null ? website.data.logo : Logo}
                alt="My Prepzone"
                className="web_logo"
              />
            </NavLink>
          </div>
          <div className="d-flex">
            {isLoggedIn && (
              <div
                className="nav-item dfc dropdown"
                style={{ marginRight: 20 }}
                onClick={() => setIsNotificationOpen(!isNotificationOpen)}
              >
                {isVisible && <span className="notification_dot"></span>}
                <svg
                  fill="currentColor"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                >
                  <path d="M8 16a2 2 0 002-2H6a2 2 0 002 2zM8 1.918l-.797.161A4.002 4.002 0 004 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 00-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5.002 5.002 0 0113 6c0 .88.32 4.2 1.22 6z" />
                </svg>
              </div>
            )}

            <div
              className="mobile_menu_icon"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? (
                <svg
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  height="1em"
                  width="1em"
                >
                  <path
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={32}
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              ) : (
                <svg fill="none" viewBox="0 0 24 24" height="1em" width="1em">
                  <path
                    fill="currentColor"
                    d="M4 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zM4 18a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zM11 11a1 1 0 100 2h8a1 1 0 100-2h-8z"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="mobile_new_menu_wrapper">
            <div className="mm_top_row">
              <div className="mm_top_left">
                <div className="company_logo">
                  <NavLink
                    className="navbar-brand"
                    to="/"
                    onClick={() => setIsOpen(!isOpen)}
                  >
                    <img
                      src={website.data !== null ? website.data.logo : Logo}
                      alt="My Prepzone"
                      className="web_logo"
                    />
                  </NavLink>
                </div>
                <div
                  className="mobile_menu_close_icon"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <svg
                    viewBox="0 0 512 512"
                    fill="currentColor"
                    height="2em"
                    width="2em"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={32}
                      d="M368 368L144 144M368 144L144 368"
                    />
                  </svg>
                </div>
              </div>
              <div className="mm_top_right" onClick={() => setIsOpen(!isOpen)}>
                <i className="fa fa-times"></i>
              </div>
            </div>
            <div className="mobile_new_menu_inner">
              {isLoggedIn ? (
                <div>
                  <li className="nav-item dfc">
                    <a
                      className="dfc"
                      style={{ cursor: "pointer" }}
                      onClick={() => setIsOpenUser(!isOpenUser)}
                    >
                      <div className="avatar_header">
                        {isUser?.profile_picture !== null ? (
                          <img src={isUser?.profile_picture} />
                        ) : (
                          <span>{isUser?.name.substring(0, 1)}</span>
                        )}
                      </div>
                      <span className="user_name">{isUser?.name}</span>
                    </a>
                  </li>
                  {isOpenUser && (
                    <ul className="dropdown-menu-mobile-menu">
                      <li>
                        <Link
                          to="/user/dashboard"
                          className="dropdown-item-mobile"
                          onClick={() => setIsOpen(!isOpen)}
                        >
                          <i className="fa fa-user-circle"></i> Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          className="dropdown-item-mobile"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsOpen(!isOpen);
                            handleLogout();
                          }}
                        >
                          <i className="fa fa-sign-out"></i> Logout
                        </a>
                      </li>
                    </ul>
                  )}
                </div>
              ) : (
                <div className="mobile_login_signup">
                  <div className="row">
                    <div className="col-6">
                      <Link
                        to="/login"
                        onClick={() => setIsOpen(!isOpen)}
                        className="btn btn-default w-100"
                      >
                        Sign In
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/regsiter"
                        onClick={() => setIsOpen(!isOpen)}
                        className="btn btn-success w-100"
                      >
                        Sign Up
                      </Link>
                    </div>
                  </div>
                </div>
              )}
              <hr />
              <div className="mt-4 text-muted text-uppercase">Main Menu</div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Home
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/about-us"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  About Us
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <div
                  className="d-flex justify-content-between align-content-center"
                  onClick={() => setIsCourseOpen(!isCoursesOpen)}
                >
                  <span className="mobile_nav_link">Courses</span>
                  <span>
                    {isCoursesOpen ? (
                      <i className="fa fa-angle-up"></i>
                    ) : (
                      <i className="fa fa-angle-down"></i>
                    )}
                  </span>
                </div>
                {isCoursesOpen && (
                  <div className="mobile_course_dropdown_wrapper">
                    <CourseCategory
                      data={bundles}
                      itemType="bundle"
                      mobileLinkHandle={mobileLinkHandle}
                    />
                    {courses.length > 0 && (
                      <>
                        {courses.map((item) => {
                          const enrolledCourses =
                            item.courses.length > 0
                              ? item.courses.filter(
                                  (c) => c.enrollment === true
                                )
                              : [];
                          return (
                            <>
                              {enrolledCourses.length > 0 &&
                                [...item.courses].sort(
                                  (a, b) =>
                                    Number(b.isPublished) -
                                    Number(a.isPublished)
                                )[0].isPublished === true && (
                                  <div key={item._id}>
                                    <CourseCategory
                                      data={item}
                                      mobileLinkHandle={mobileLinkHandle}
                                    />
                                  </div>
                                )}
                            </>
                          );
                        })}
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/blogs"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Blogs
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/daily-reads"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Daily Reads
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/practice-forum"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Practice Forum
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/results"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Results
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/testimonials"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Testimonials
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/contact-us"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  Contact Us
                </NavLink>
              </div>
              <div className="mobile_new_menu_item">
                <NavLink
                  to="/cat-iim-mba-college-predictor"
                  className="mobile_nav_link"
                  onClick={() => setIsOpen(false)}
                >
                  <span class="bell fa fa-bell"></span> B-School Predictor
                </NavLink>
              </div>
            </div>
          </div>
        )}

        {isNotificationOpen && (
          <div className="mobile_new_menu_wrapper">
            <div className="mobile_new_menu_inner">
              <div className="d-flex justify-content-between">
                <h4>Notifications</h4>
                <span
                  className="close_icon"
                  onClick={() => setIsNotificationOpen(false)}
                  style={{ fontSize: 26, opacity: 0.7 }}
                >
                  <i className="fa fa-times"></i>
                </span>
              </div>
              <hr />
              <div className="notification_list">
                {notifications.length > 0 ? (
                  <>
                    {notifications.map((item) => {
                      const isReaded = item.readby.some(
                        (us) => us.readerId === isUser?.id
                      );
                      return (
                        <Link
                          to={`${item.type === "course" && "/course"}/${
                            item.productId
                          }`}
                          className={`notification ${isReaded ? "read" : " "}`}
                          key={item._id}
                          onClick={() =>
                            !isReaded
                              ? updateNotificationStatus(item._id)
                              : console.log("clicked")
                          }
                        >
                          <div className="notification_image">
                            <i className="fa fa-refresh" />
                          </div>
                          <div className="notification_content">
                            <div className="title_and_date">
                              <strong>Admin</strong> {item.message}
                            </div>
                            <span className="notification_date">
                              {moment(item.createdAt).fromNow()}
                            </span>
                          </div>
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  <small>No notifications</small>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* End Mobile Navbar */}

      {/* Right Navbar */}
      {!isOpen && <RightNavBar />}
      {/* End Right Navbar */}
    </>
  );
}

export default HeaderNew;
